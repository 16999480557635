import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IngestionPanel from './components/ingestion/IngestionPanel';
import SystemPrompts from './components/prompts/SystemPrompts';

function App() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title');
    }, [t]);

    return (
        <div className="text-base">
            <IngestionPanel />
            <SystemPrompts />
        </div>
    );
}

export default App;
