import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IngestionTaskState } from '../../api-client/models';
import { useIngestionApi } from '../../hooks/useIngestionApi';
import { Alert, Button, Icon } from '../../shared/components';
import IngestionStateIcon from './IngestionStateIcon';

function IngestionPanel() {
    const { t } = useTranslation();
    const { getStatus, download, crack, chunk, embed, index, ingestionRequestState } = useIngestionApi();
    const [ingestionState, setIngestionState] = useState<IngestionTaskState>();

    const pollStatus = async () => {
        const response = await getStatus();
        setIngestionState(response);
        if (response?.taskState === 'Processing') {
            setTimeout(pollStatus, 2000);
        }
    };

    const handleActionClick = async (action: () => Promise<IngestionTaskState>): Promise<void> => {
        const response = await action();
        setIngestionState(() => response);
        pollStatus();
    };

    useEffect(() => {
        pollStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actions = [
        { label: 'downloading', action: download },
        { label: 'cracking', action: crack },
        { label: 'chunking', action: chunk },
        { label: 'embedding', action: embed },
        { label: 'indexing', action: index, renderArrow: false },
    ];

    const renderButton = (label: string, action: () => Promise<IngestionTaskState>, renderArrow: boolean = true) => (
        <>
            <Button
                key={label}
                className="self-stretch"
                variant="secondary"
                disabled={ingestionState?.taskState === 'Processing' || ingestionRequestState === 'loading'}
                onClick={() => handleActionClick(action)}
            >
                {t(`ingestion.task.${label}`)}
            </Button>
            {renderArrow && <Icon icon="arrowDown" size="medium" />}
        </>
    );

    return (
        <div className="inline-flex w-full flex-col items-center justify-start gap-8 p-4">
            <h1 className="text-lg font-bold">{t('ingestion.title')}</h1>
            {ingestionRequestState === 'error' && <Alert variant="error">{t('error')}</Alert>}
            <div className="inline-flex flex-row items-start justify-start gap-8">
                <div className="inline-flex flex-col items-center justify-center gap-1">
                    {actions.map(({ label, action, renderArrow }) => renderButton(label, action, renderArrow))}
                </div>

                {ingestionState && (
                    <div className="inline-flex w-80 flex-col items-start justify-start gap-2 rounded bg-white px-3 py-2 drop-shadow">
                        <div className="inline-flex flex-row items-start self-stretch">
                            <div className="-ml-6 -mt-6">
                                <IngestionStateIcon state={ingestionState?.taskState} />
                            </div>
                            <div className="inline-flex w-full flex-col items-end justify-start">
                                <span className="mb-8 text-sm">
                                    {ingestionState?.task ? `${t('ingestion.task.' + ingestionState?.task)}` : '-'}
                                </span>
                                <span className="text-lg">
                                    <strong>{`${t('ingestion.taskState.' + ingestionState?.taskState.toLowerCase())}`}</strong>
                                </span>
                                <span className="text-sm">{ingestionState?.timestamp}</span>
                            </div>
                        </div>
                        {ingestionState?.message && (
                            <>
                                <hr className="self-stretch border-input" />
                                <p className="self-stretch text-left text-sm">{ingestionState?.message}</p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default IngestionPanel;
