import { useEffect, useState } from 'react';
import { Button } from '../../shared/components';
import { CustomWindow } from '../../utils/customWindow';

type Identifier = 'RESPONSE_SYSTEM_PROMPT' | 'HYDE_SYSTEM_PROMPT' | 'REFINEMENT_SYSTEM_PROMPT';
declare const window: CustomWindow;
function SystemPrompts() {
    const identifiers: Identifier[] = ['RESPONSE_SYSTEM_PROMPT', 'HYDE_SYSTEM_PROMPT', 'REFINEMENT_SYSTEM_PROMPT'];
    const backendUrl = window._env_.MILO_AI_BACKEND_URL;

    const [prompts, setPrompts] = useState<Record<Identifier, string>>({
        RESPONSE_SYSTEM_PROMPT: '',
        HYDE_SYSTEM_PROMPT: '',
        REFINEMENT_SYSTEM_PROMPT: '',
    });

    useEffect(() => {
        identifiers.forEach((id) => {
            fetch(backendUrl + `/retrieve_system_prompt?prompt_type=${id}`)
                .then((response) => response.json())
                .then((data) => {
                    setPrompts((prevPrompts) => ({
                        ...prevPrompts,
                        [id]: data.prompt,
                    }));
                })
                .catch((error) => {
                    console.error(`Error fetching prompt for ${id}:`, error);
                });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (id: Identifier, value: string) => {
        setPrompts((prevPrompts) => ({
            ...prevPrompts,
            [id]: value,
        }));
    };

    const handleSave = (id: Identifier) => {
        const prompt = prompts[id];
        fetch(`${backendUrl}/store_system_prompt`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prompt_type: id, prompt: prompt }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to save prompt');
                }
                alert('Prompt saved successfully!');
            })
            .catch((error) => {
                console.error(`Error saving prompt for ${id}:`, error);
            });
    };

    return (
        <div className="inline-flex w-full flex-col items-center justify-start gap-8 p-4">
            You can override the system prompts here. If a system prompt is left empty, the default one from the backend
            will be used. To restore the default prompt, simply delete the text in the input field and click Save.
            {identifiers.map((id) => (
                <div
                    key={id}
                    className="inline-flex w-80 flex-col items-start justify-start gap-2 rounded bg-white px-3 py-2 drop-shadow"
                >
                    <label htmlFor={id} className="inline-flex w-full flex-col items-end justify-start">
                        {id}
                    </label>
                    <textarea
                        id={id}
                        value={prompts[id]}
                        onChange={(e) => handleChange(id, e.target.value)}
                        className="inline-flex flex-row items-start self-stretch border"
                    />
                    <Button onClick={() => handleSave(id)} className="self-stretch" variant="secondary">
                        Save
                    </Button>
                </div>
            ))}
        </div>
    );
}

export default SystemPrompts;
