import React from 'react';
import { IngestionTaskStateTaskStateEnum } from '../../api-client/models';
import { Icon, Spinner } from '../../shared/components';

interface IngestionStateIconProps {
    state?: IngestionTaskStateTaskStateEnum;
}

export const IngestionStateIcon: React.FC<IngestionStateIconProps> = ({ state }) => {
    let classNameOuterCircle = '';
    let classNameInnerCircle = '';
    switch (state) {
        case 'Idle':
            classNameOuterCircle = 'border-input/10';
            classNameInnerCircle = 'border-input/15';
            break;
        case 'Processing':
            classNameOuterCircle = 'border-white';
            classNameInnerCircle = 'border-white';
            break;
        case 'Completed':
            classNameOuterCircle = 'border-status-success/10';
            classNameInnerCircle = 'border-status-success/45';
            break;
        case 'Failed':
            classNameOuterCircle = 'border-status-error/10';
            classNameInnerCircle = 'border-status-error/45';
            break;
    }

    return (
        <div
            className={`inline-flex size-20 flex-col items-center justify-center rounded-full border-[28px] bg-white ${classNameOuterCircle}`}
        >
            <div
                className={`inline-flex size-14 flex-col items-center justify-center rounded-full border-[18px] ${classNameInnerCircle}`}
            >
                <span className="fill-white">
                    {(state === 'Idle' || !state) && <Icon icon="radioButton" size="large" />}
                </span>
                <Spinner show={state === 'Processing'} />
                <span className="fill-status-success">
                    {state === 'Completed' && <Icon icon="checkCircle" size="large" />}
                </span>
                <span className="fill-status-error">
                    {state === 'Failed' && <Icon icon="warningCircle" size="large" />}
                </span>
            </div>
        </div>
    );
};

export default IngestionStateIcon as React.FC<IngestionStateIconProps>;
