// hooks/useReplyPost.ts
import { useState } from 'react';
import { Configuration, ConfigurationParameters, DefaultApi } from '../api-client';
import { CustomWindow } from '../utils/customWindow';

declare const window: CustomWindow;
type RequestState = 'idle' | 'loading' | 'success' | 'error';

const configParams: ConfigurationParameters = {
    basePath: (() => window._env_.MILO_AI_ADMIN_BACKEND_URL)(),
};
const config = new Configuration(configParams);
const apiInstance = new DefaultApi(config);

export const useIngestionApi = () => {
    const [ingestionRequestState, setRequestState] = useState<RequestState>('idle');

    const getStatus = async () => {
        setRequestState('loading');

        try {
            const response = await apiInstance.ingestionGetStatus();
            setRequestState('success');
            return response;
        } catch (error: any) {
            setRequestState('error');
            return;
        }
    };

    const download = async () => {
        return await startIngestionTask(() => apiInstance.ingestionDownload());
    };

    const crack = async () => {
        return await startIngestionTask(() => apiInstance.ingestionCrack());
    };

    const chunk = async () => {
        return await startIngestionTask(() => apiInstance.ingestionChunk());
    };

    const embed = async () => {
        return await startIngestionTask(() => apiInstance.ingestionEmbed());
    };

    const index = async () => {
        return await startIngestionTask(() => apiInstance.ingestionIndex());
    };

    const startIngestionTask = async (apiFunction: () => Promise<any>) => {
        setRequestState('loading');
        try {
            const response = await apiFunction();
            setRequestState('success');
            return response;
        } catch (error: any) {
            if (error.response?.status === 409) {
                return await getStatus();
            } else {
                setRequestState('error');
            }
            return;
        }
    };

    return { getStatus, download, crack, chunk, embed, index, ingestionRequestState };
};
