import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
    de: {
        translation: {
            title: 'VTG AI Admin Console',
            error: 'Bei der Anfrge ist ein unerwarteter Fehler aufgetreten.',
            ingestion: {
                title: 'Ingestion',
                task: {
                    downloading: 'Download',
                    cracking: 'Crack',
                    chunking: 'Chunk',
                    embedding: 'Embed',
                    indexing: 'Index',
                },
                taskState: {
                    idle: 'Warten',
                    processing: 'In Bearbeitung',
                    completed: 'Abgeschlossen',
                    failed: 'Fehlerhaft',
                },
            },
        },
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: resources,
        fallbackLng: 'de',
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lang',
        },
    });
