/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IngestionTaskState,
} from '../models/index';
import {
    IngestionTaskStateFromJSON,
    IngestionTaskStateToJSON,
} from '../models/index';

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get Status
     */
    async getStatusGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Status
     */
    async getStatusGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Chunk
     */
    async ingestionChunkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/chunk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Chunk
     */
    async ingestionChunk(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionChunkRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Crack
     */
    async ingestionCrackRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/crack`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Crack
     */
    async ingestionCrack(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionCrackRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Download
     */
    async ingestionDownloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Download
     */
    async ingestionDownload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionDownloadRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Embed
     */
    async ingestionEmbedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/embed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Embed
     */
    async ingestionEmbed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionEmbedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Get Status
     */
    async ingestionGetStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/get_status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Get Status
     */
    async ingestionGetStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionGetStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Ingestion Index
     */
    async ingestionIndexRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IngestionTaskState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ingestion/index`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngestionTaskStateFromJSON(jsonValue));
    }

    /**
     * Ingestion Index
     */
    async ingestionIndex(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IngestionTaskState> {
        const response = await this.ingestionIndexRaw(initOverrides);
        return await response.value();
    }

}
