/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IngestionTaskState
 */
export interface IngestionTaskState {
    /**
     * 
     * @type {string}
     * @memberof IngestionTaskState
     */
    taskState: IngestionTaskStateTaskStateEnum;
    /**
     * 
     * @type {string}
     * @memberof IngestionTaskState
     */
    task: string;
    /**
     * 
     * @type {string}
     * @memberof IngestionTaskState
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof IngestionTaskState
     */
    message: string;
}


/**
 * @export
 */
export const IngestionTaskStateTaskStateEnum = {
    Idle: 'Idle',
    Processing: 'Processing',
    Completed: 'Completed',
    Failed: 'Failed'
} as const;
export type IngestionTaskStateTaskStateEnum = typeof IngestionTaskStateTaskStateEnum[keyof typeof IngestionTaskStateTaskStateEnum];


/**
 * Check if a given object implements the IngestionTaskState interface.
 */
export function instanceOfIngestionTaskState(value: object): value is IngestionTaskState {
    if (!('taskState' in value) || value['taskState'] === undefined) return false;
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function IngestionTaskStateFromJSON(json: any): IngestionTaskState {
    return IngestionTaskStateFromJSONTyped(json, false);
}

export function IngestionTaskStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngestionTaskState {
    if (json == null) {
        return json;
    }
    return {
        
        'taskState': json['task_state'],
        'task': json['task'],
        'timestamp': json['timestamp'],
        'message': json['message'],
    };
}

export function IngestionTaskStateToJSON(value?: IngestionTaskState | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task_state': value['taskState'],
        'task': value['task'],
        'timestamp': value['timestamp'],
        'message': value['message'],
    };
}

